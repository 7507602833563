import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";

export default inject("data")(observer(class Autochtone extends Component {

	render() {
		return (
			<section>
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 0 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_abenakis.png" alt="Abenakis" style={{top : '1260px', left: '480px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 1 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_algonquins.png" alt="Algonquins" style={{top : '1214px', left: '110px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 2 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1 || this.props.data.menuSubSectionNo === 3)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_atikamekws.png" alt="Cris" style={{top : '1140px', left: '330px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 3 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1 || this.props.data.menuSubSectionNo === 0 || this.props.data.menuSubSectionNo === 1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_cris.png" alt="Cris" style={{top : '695px', left: '60px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 4 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_hurons-wendats.png" alt="Hurons-Wendats" style={{top : '1200px', left: '520px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 5 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1 || this.props.data.menuSubSectionNo === 2)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_innus.png" alt="Innus" style={{top : '650px', left: '455px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 6 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1 || this.props.data.menuSubSectionNo === 0)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_inuits.png" alt="Inuits" style={{top : '260px', left: '5px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 7 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_malecites.png" alt="Malecites" style={{top : '1130px', left: '600px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 8 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_micmacs.png" alt="Micmaque" style={{top : '1000px', left: '700px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 9 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_mohawks.png" alt="Mohawks" style={{top : '1365px', left: '400px'}} />
					:
						null
				}
				{
					(this.props.data.menuSectionNo === 0 && (this.props.data.menuSubSectionNo === 10 || this.props.data.menuSubSectionNo === -1)) ||
					(this.props.data.menuSectionNo === 1 && (this.props.data.menuSubSectionNo === -1)) ||
					this.props.data.menuSectionNo === -1 ?
						<img src="/images/auto_naskapis.png" alt="Naskapis" style={{top : '635px', left: '520px'}} />
					:
						null
				}
			</section>
		);
  	}
}));