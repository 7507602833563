const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">
<centrale>
<ID>Sol1</ID>
<TYPE>Panneau solaire</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Varennes</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>480</X>
<Y>1355</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>Sol2</ID>
<TYPE>Panneau solaire</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>La Prairie</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>470</X>
<Y>1375</Y>
<CONSTR>false</CONSTR>
</centrale>

</dataroot> `
export default xml;