const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>J1</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale La Grande-1</NAME>
<TITRE>Mise en service entre 1993 et en 1995, la centrale La Grande-1 comporte douze groupes turbines-alternateurs.
||
Types de turbines : à hélice à pales fixes.
</TITRE>
<IMAGE>j1_small.jpg</IMAGE>
<X>75.7</X>
<Y>813.4</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J2</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale Jean-Lesage</NAME>
<TITRE>Mise en service entre 1965 et 1967, la centrale Jean-Lesage comporte huit groupes turbines-alternateurs.
||Type de turbine : Francis.
</TITRE>
<IMAGE>j2_small.jpg</IMAGE>
<X>617.9</X>
<Y>1020.0</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J3</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale de la Première-Chute</NAME>
<TITRE>Mise en service en 1968, la centrale de la Première-Chute comporte quatre groupes turbines-alternateurs.
||Type de turbine : Francis.
</TITRE>
<IMAGE>j3_small.jpg</IMAGE>
<X>121.3</X>
<Y>1283.8</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J5</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale de la Rivière-des-Prairies</NAME>
<TITRE>Mise en service en 1929, la centrale de la Rivière-des-Prairies comporte six groupes turbines-alternateurs.
||Type de turbine : à hélice.
</TITRE>
<IMAGE>j5_small.jpg</IMAGE>
<X>455.8</X>
<Y>1355</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J6</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale de Beauharnois</NAME>
<TITRE>Mise en service entre 1932 et 1961, la centrale de Beauharnois comporte 38 groupes turbines-alternateurs.
||Types de turbines : Francis et à hélice.
</TITRE>
<IMAGE>j6_small.jpg</IMAGE>
<X>447.3</X>
<Y>1395.6</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J7</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale de Shawinigan-2</NAME>
<TITRE>Mise en service en 1911, la centrale de Shawinigan-2 comporte huit groupes turbines-alternateurs.
||Type de turbine : Francis.
</TITRE>
<IMAGE>j7_small.jpg</IMAGE>
<X>476.9</X>
<Y>1276.9</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J8</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale des Rapides-Farmer</NAME>
<TITRE>Mise en service en 1927, la centrale des Rapides-Farmer comporte cinq groupes turbines-alternateurs.
||Type de turbine : Francis.
</TITRE>
<IMAGE>j8_small.jpg</IMAGE>
<X>353.1</X>
<Y>1392.8</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J9</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale de Carillon</NAME>
<TITRE>Mise en service en 1962, la centrale de Carillon comporte quatorze groupes turbines-alternateurs.
||Type de turbine : Kaplan.
</TITRE>
<IMAGE>j9_small.jpg</IMAGE>
<X>395</X>
<Y>1375.1</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J11</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>René-Lévesque (Manic-3)</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>606.3</X>
<Y>1001.2</Y>
</centrale>

<centrale>
<ID>J12</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Outardes-3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>589.8</X>
<Y>1005.9</Y>
</centrale>

<centrale>
<ID>J13</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Bersimis-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>586.6</X>
<Y>1045.2</Y>
</centrale>

<centrale>
<ID>J14</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Outardes-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>620.8</X>
<Y>1047.3</Y>
</centrale>

<centrale>
<ID>J15</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Péribonka</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>493.9</X>
<Y>1059.7</Y>
</centrale>

<centrale>
<ID>J16</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Laforge-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>416.9</X>
<Y>707.0</Y>
</centrale>

<centrale>
<ID>J17</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Trenche</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>460.6</X>
<Y>1208.6</Y>
</centrale>

<centrale>
<ID>J18</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La Tuque</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>463.6</X>
<Y>1240.0</Y>
</centrale>

<centrale>
<ID>J19</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Beaumont</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>462.6</X>
<Y>1223.8</Y>
</centrale>

<centrale>
<ID>J20</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rocher-de-Grand-Mère</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>498.6</X>
<Y>1263.6</Y>
</centrale>

<centrale>
<ID>J21</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Paugan</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>331.5</X>
<Y>1367.3</Y>
</centrale>

<centrale>
<ID>J22</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Shawinigan-3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>471.3</X>
<Y>1263.5</Y>
</centrale>

<centrale>
<ID>J23</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Manic-1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>621.6</X>
<Y>1033.8</Y>
</centrale>

<centrale>
<ID>J24</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rapides-des-Îles</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>136.3</X>
<Y>1287.3</Y>
</centrale>

<centrale>
<ID>J25</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chelsea</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>351.2</X>
<Y>1379.8</Y>
</centrale>

<centrale>
<ID>J26</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La Gabelle</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>488.9</X>
<Y>1286.3</Y>
</centrale>

<centrale>
<ID>J27</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Les Cèdres</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>433.2</X>
<Y>1393.7</Y>
</centrale>

<centrale>
<ID>J28</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rapides-des-Quinze</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>148.9</X>
<Y>1277.9</Y>
</centrale>

<centrale>
<ID>J29</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rocher-de-Grand-Mère 1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>490.5</X>
<Y>1252.5</Y>
</centrale>

<centrale>
<ID>J30</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chute-des-Chats</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>323.3</X>
<Y>1405.1</Y>
</centrale>

<centrale>
<ID>J31</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rapides-des-Cœurs</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>426.6</X>
<Y>1207.1</Y>
</centrale>

<centrale>
<ID>J32</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Rapide-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>193.3</X>
<Y>1248.0</Y>
</centrale>

<centrale>
<ID>J33</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chute-Allard</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>407.6</X>
<Y>1202.2</Y>
</centrale>

<centrale>
<ID>J34</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Bryson</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>296.2</X>
<Y>1391.8</Y>
</centrale>

<centrale>
<ID>J35</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chute-Hemmings</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>481.2</X>
<Y>1368.8</Y>
</centrale>

<centrale>
<ID>J36</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Hull-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>323.3</X>
<Y>1405.1</Y>
</centrale>

<centrale>
<ID>J37</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Drummondville</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>478.4</X>
<Y>1354.9</Y>
</centrale>

<centrale>
<ID>J38</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chute-Bell</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>380.4</X>
<Y>1363.3</Y>
</centrale>

<centrale>
<ID>J39</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Mitis-1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>636.1</X>
<Y>1089.3</Y>
</centrale>

<centrale>
<ID>J40</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Mitis-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>639.1</X>
<Y>1102.8</Y>
</centrale>

<centrale>
<ID>J41</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>Chute-Burroughs</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>547.5</X>
<Y>1367.8</Y>
</centrale>

<centrale>
<ID>K1</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>L’aménagement Robert-Bourassa</NAME>
<TITRE>L’aménagement Robert-Bourassa comprend un barrage, un évacuateur de crues, un réservoir et deux centrales souterraines. Mise en service entre 1979 et 1981, la centrale Robert-Bourassa comporte seize groupes turbines-alternateurs.
||Type de turbine : Francis.</TITRE>
<IMAGE>k1_small.jpg</IMAGE>
<X>132.4</X>
<Y>805.0</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K2</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale Manic-5 et le barrage Daniel-Johnson</NAME>
<TITRE>Mise en service en 1970, la centrale Manic-5 comporte huit groupes turbines-alternateurs.
||Type de turbine : Francis.</TITRE>
<IMAGE>k2_small.jpg</IMAGE>
<X>568</X>
<Y>935</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K3</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La Grande-2-A</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>118.1</X>
<Y>807.8</Y>
</centrale>

<centrale>
<ID>K4</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La Grande-3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>195.8</X>
<Y>802.9</Y>
</centrale>

<centrale>
<ID>K5</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La Grande-4</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>301.5</X>
<Y>770.8</Y>
</centrale>

<centrale>
<ID>K6</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Bersimis-1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>560.8</X>
<Y>1042.2</Y>
</centrale>

<centrale>
<ID>K7</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Manic-5-PA</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>583</X>
<Y>935</Y>
</centrale>

<centrale>
<ID>K8</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Sainte-Marguerite-3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>667.0</X>
<Y>905.0</Y>
</centrale>

<centrale>
<ID>K9</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Laforge-1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>363.7</X>
<Y>745.0</Y>
</centrale>

<centrale>
<ID>K10</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Outardes-4</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>581.2</X>
<Y>994.5</Y>
</centrale>

<centrale>
<ID>K11</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Toulnustouc</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>612.7</X>
<Y>979.9</Y>
</centrale>

<centrale>
<ID>K12</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Bernard-Landry</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>210.3</X>
<Y>900.1</Y>
</centrale>

<centrale>
<ID>K13</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Brisay</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>443.1</X>
<Y>712.0</Y>
</centrale>

<centrale>
<ID>K14</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>McCormick</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>636.5</X>
<Y>1033.8</Y>
</centrale>

<centrale>
<ID>K15</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Rapide-Blanc</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>452.6</X>
<Y>1196.3</Y>
</centrale>

<centrale>
<ID>K16</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Rapide-7</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>209.4</X>
<Y>1253.1</Y>
</centrale>

<centrale>
<ID>K17</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Mercier</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>317.1</X>
<Y>1313.3</Y>
</centrale>

<centrale>
<ID>K18</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Hart-Jaune</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>576.5</X>
<Y>859.0</Y>
</centrale>

<centrale>
<ID>K19</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Lac-Robertson</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>906.5</X>
<Y>829.0</Y>
</centrale>

<centrale>
<ID>K21</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine 2</NAME>
<TITRE>La construction du complexe de la Romaine s’échelonnera jusqu’en 2020. Ces quatre centrales ajouteront 1 550 MW au réseau d’Hydro-Québec.</TITRE>
<IMAGE>k20_small.jpg</IMAGE>
<X>775.95</X>
<Y>866.25</Y>
<CLICKABLE>false</CLICKABLE>
</centrale>

<centrale>
<ID>K20</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine-1</NAME>
<TITRE>Mise en service en 2015, la centrale de la Romaine-1 comporte 2 groupes                      turbines-alternateurs.</TITRE>
<IMAGE>k108_small.jpg</IMAGE>
<X>784.0</X>
<Y>892.0</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K22</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine 3</NAME>
<TITRE>La construction du complexe de la Romaine s’échelonnera jusqu’en 2020. Ces quatre centrales ajouteront 1 550 MW au réseau d’Hydro-Québec.</TITRE>
<IMAGE>k20_small.jpg</IMAGE>
<X>761.0</X>
<Y>846.0</Y>
<CLICKABLE>false</CLICKABLE>
</centrale>

<centrale>
<ID>K23</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine 4</NAME>
<TITRE>La construction du complexe de la Romaine s’échelonnera jusqu’en 2020. Ces quatre centrales ajouteront 1 550 MW au réseau d’Hydro-Québec.</TITRE>
<IMAGE>k20_small.jpg</IMAGE>
<X>749.5</X>
<Y>830.85</Y>
<CONSTR>true</CONSTR>
<CLICKABLE>false</CLICKABLE>
</centrale>

<centrale>
<ID>K24</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Sarcelle-Rupert</NAME>
<TITRE></TITRE>
<IMAGE>k21_small.jpg</IMAGE>
<X>190.0</X>
<Y>878.0</Y>
<CLICKABLE>false</CLICKABLE>
</centrale>

</dataroot> `
export default xml;
