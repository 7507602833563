const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>J102</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale |||Jean-Lesage (Manic-2)</NAME>
<TITRE>Mise en service entre 1965 et 1967, la centrale Jean-Lesage comporte huit groupes turbines-alternateurs.
|||Puissance installée : 1 229 MW.
|||Ce barrage est au second rang des barrages-poids à joints évidés au monde.
</TITRE>
<IMAGE>j2_small.jpg</IMAGE>
<X>368</X>
<Y>1130</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J103</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale René-Lévesque (Manic-3)</NAME>
<TITRE>Mise en service en 1975, la centrale souterraine René-Lévesque (auparavant nommée Manic-3) comporte six groupes turbines-alternateurs.
|||Puissance installée : 1 246 MW.
|||Le barrage-poids en sable et gravier a une hauteur de 107 m et une longueur de 395 m. Sa particularité est la présence d’un mur d’étanchéité en béton de 122 m de profondeur.
</TITRE>
<IMAGE>j103_small.jpg</IMAGE>
<X>308</X>
<Y>1054</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J106</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale Bersimis-2</NAME>
<TITRE>Mise en service en 1959, la centrale Bersimis-2 comporte cinq groupes turbines-alternateurs.
|||Puissance installée : 869 MW.
|||Le barrage-poids en béton a une hauteur de 84 m et une longueur de 640 m.
</TITRE>
<IMAGE>j106_small.jpg</IMAGE>
<X>260</X>
<Y>1200</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K101</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale Manic-5 et le barrage Daniel-Johnson</NAME>
<TITRE>Mise en service en 1970, la centrale Manic-5 comporte huit groupes turbines-alternateurs.
|||Puissance installée : 1 596 MW.
|||Le barrage à voûtes multiples et à contreforts a une hauteur de 214 m et une longueur de 1,3 km.
</TITRE>
<IMAGE>k2_small.jpg</IMAGE>
<X>218</X>
<Y>861</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K103</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale aux Outardes-4</NAME>
<TITRE>Mise en service en 1969, la centrale aux     Outardes-4 comporte quatre groupes turbines-alternateurs.
|||Puissance installée : 785 MW.
|||Le barrage en enrochement a une hauteur de      122 m et une longueur de 649 m.  Dix ouvrages (barrage principal et digues) retiennent l’eau du réservoir.
</TITRE>
<IMAGE>k103_small.jpg</IMAGE>
<X>267</X>
<Y>1078</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K104</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale Bersimis-1</NAME>
<TITRE>Mise en service en 1956, la centrale souterraine Bersimis-1 comporte huit groupes turbines-alternateurs.
|||Puissance installée : 1 178 MW.
|||Le barrage-poids en béton a une hauteur de 61 m et une longueur de 320 m. La galerie d’amenée a une longueur de 12 km.
</TITRE>
<IMAGE>k104_small.jpg</IMAGE>
<X>214</X>
<Y>1186</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K105</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale de la Toulnustouc</NAME>
<TITRE>Mise en service en 2005, la centrale de la Toulnustouc comporte deux groupes turbines-alternateurs.
|||Puissance installée : 526 MW.
|||Le barrage-poids en remblai et masque amont en béton a une hauteur de 77 m et une longueur de 560 m.
</TITRE>
<IMAGE>k105_small.jpg</IMAGE>
<X>354</X>
<Y>984</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K106</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale de la Sainte-Marguerite-3</NAME>
<TITRE>Mise en service en 2003, la centrale souterraine de la Sainte-Marguerite-3 comporte deux groupes turbines-alternateurs.
|||Puissance installée : 882 MW.
|||Le barrage-poids en enrochement Denis-Perron a une hauteur de 171 m et une longueur de 380 m. La hauteur de chute de 330 m est la plus haute exploitée par Hydro-Québec.
</TITRE>
<IMAGE>k106_small.jpg</IMAGE>
<X>480</X>
<Y>777</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K107</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale de la Hart-Jaune</NAME>
<TITRE>Mise en service en 1960, la centrale de la Hart-Jaune comporte trois groupes turbines-alternateurs.
|||Puissance installée : 51 MW.
|||Le barrage principal en terre homogène a une hauteur de 18 m et une longueur de 468 m.
</TITRE>
<IMAGE>k107_small.jpg</IMAGE>
<X>249</X>
<Y>605</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K102</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>La centrale Manic-5-PA</NAME>
<TITRE>Mise en service en 1989, la centrale souterraine Manic-5-PA comporte quatre groupes turbines-alternateurs.
|||Puissance installée : 1 064 MW.
</TITRE>
<IMAGE>k102_small.jpg</IMAGE>
<X>236</X>
<Y>877</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J105</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale aux Outardes-3</NAME>
<TITRE>Mise en service en 1969, la centrale souterraine aux Outardes-3 comporte quatre groupes turbines-alternateurs.
|||Puissance installée : 1 026 MW.
|||Le barrage-poids en béton a une hauteur de 85 m et une longueur de 304 m.
</TITRE>
<IMAGE>j105_small.jpg</IMAGE>
<X>297</X>
<Y>1097</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J101</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale Manic-1</NAME>
<TITRE>Mise en service en 1966, la centrale Manic-1 comporte trois groupes turbines-alternateurs.
|||Puissance installée : 184 MW.
|||Le barrage-poids en béton et en enrochement a une hauteur de 35 m et une longueur de 440 m. La hauteur de chute varie selon les marées.
</TITRE>
<IMAGE>j101_small.jpg</IMAGE>
<X>374</X>
<Y>1162</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>J104</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Au fil de l’eau</SOUS-TYPE>
<NAME>La centrale aux Outardes-2</NAME>
<TITRE>Mise en service en 1978, la centrale aux     Outardes-2 comporte trois groupes turbines-alternateurs.
|||Puissance installée : 523 MW.
|||Le barrage-poids en enrochement a une hauteur de 55 m et une longueur de 1 636 m.
</TITRE>
<IMAGE>j104_small.jpg</IMAGE>
<X>370</X>
<Y>1195</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K108</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine-1</NAME>
<TITRE>Mise en service en 2015, la centrale de la Romaine-1 comporte 2 groupes                      turbines-alternateurs.
|||Puissance installée : 270 MW.</TITRE>
<IMAGE>k108_small.jpg</IMAGE>
<X>958</X>
<Y>684</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K109</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine-2</NAME>
<TITRE>Mise en service en 2014, la centrale de la Romaine-2 comporte 2 groupes                      turbines-alternateurs.
|||Puissance installée : 640 MW.</TITRE>
<IMAGE>k109_small.jpg</IMAGE>
<X>940</X>
<Y>627</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K110</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine-3</NAME>
<TITRE>Mise en service en 2017, la centrale de la Romaine-3 comporte 2 groupes                      turbines-alternateurs.
|||Puissance installée : 395 MW.</TITRE>
<IMAGE>k110_small.jpg</IMAGE>
<X>871</X>
<Y>526</Y>
<CONSTR>false</CONSTR>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>K111</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>À réservoir</SOUS-TYPE>
<NAME>Romaine-4</NAME>
<TITRE>En chantier, la centrale de la Romaine-4 comportera 2 groupes turbines-alternateurs.
|||Puissance installée projetée : 245 MW.</TITRE>
<IMAGE>k111_small.jpg</IMAGE>
<X>834</X>
<Y>498</Y>
<CONSTR>true</CONSTR>
<CLICKABLE>true</CLICKABLE>
</centrale>

</dataroot> `
export default xml;
