import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { observable } from "mobx";

let savedData = {
    lastActivityTime : null,
    onScreenSaver : false,
    previousPath : "/",
    menuSectionNo : -1,
    menuSubSectionNo : -1,
    text : null,
    pois : null,
    showHelp : false,
    forceHideHelpButton: false,
    forcedMainTitle : null
};

const data = observable(savedData);

ReactDOM.render(<App data={data} />, document.getElementById('root'));
