import React, { Component} from 'react';
import { observer, inject } from "mobx-react";
import Button from "./button";

export default inject("data")(observer(class Help extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bigImage : null
        }

        this.previousSectionNo = null;
        this.previousHadHelp = false;
        this.previousSubSectionNo = null;
    }

	toggleHelpView() {
        this.props.data.showHelp = !this.props.data.showHelp;
    }

    showBigImage(name) {
		if (name.indexOf("legende_histoire x2.jpg") === -1 && name.indexOf("legende_histoire x3.jpg") === -1) {
            const img = new Image();
            img.onload = () => {
                this.setState({
                    bigImage : name
                })
            }
            img.onerror = () => {
                if (name.indexOf(".jpg")) {
                    name = name.replace(".jpg", ".png");
                    this.showBigImage(name)
                }
            }

            img.src = name;
        }
    }

    hideBigImage() {
        this.setState({
            bigImage : null
        })
    }

    render() {
        let helpTitle = "";
        let helpText = "";
        let helpImage = null;
        let helpImage2 = null;
        let helpCustomClass = "help-" + this.props.section + " help-no-" + this.props.data.menuSectionNo + " help-no-" + this.props.data.menuSectionNo + "_" + this.props.data.menuSubSectionNo;
        let moveCSS = null;

		if (this.props.data.menuSectionNo === -1) {
			helpTitle = this.props.data.text.help !== null ? this.props.data.text.help.title : null;
            helpText = this.props.data.text.help !== null ? this.props.data.text.help.text : null;
            helpImage = this.props.data.text.help !== null ? this.props.data.text.help.image : null;
            helpImage2 = this.props.data.text.help !== null ? this.props.data.text.help.image2 : null;
		}
		else {
            if (this.props.text.sections[this.props.data.menuSectionNo].help !== null) {
                helpTitle = this.props.data.text.sections[this.props.data.menuSectionNo].help.title;
                helpText = this.props.data.text.sections[this.props.data.menuSectionNo].help.text;
                helpImage = this.props.data.text.sections[this.props.data.menuSectionNo].help.image;
                helpImage2 = this.props.data.text.sections[this.props.data.menuSectionNo].help.image2;
            }

            if (this.props.data.menuSubSectionNo !== -1) {
                if (this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help !== null) {
                    helpTitle = this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help.title;
                    helpText = this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help.text;
                    helpImage = this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help.image;
                    helpImage2 = this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help.image2;

                    if (this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].help.mustWrapText) {
                        helpText = "<div>" + helpText + "</div>";
                    }
                }
            }
        }

        let hasHelp = helpTitle !== null && helpTitle !== "";
        let helpNode = document.querySelector(".help-btn-container");
        let exists = helpNode !== null;


        if (this.props.section === "production") {
            if (this.props.data.menuSectionNo === 0 && this.props.data.menuSubSectionNo === 3) {
                if (this.previousHadHelp) {
                    moveCSS = "show-help-move-down";
                }
                else {
                    moveCSS = "has-help-bottom";
                }
            }
            else {
                if (this.previousSectionNo === 0 && this.previousSubSectionNo === 3) {
                    if (hasHelp) {
                        moveCSS = "show-help-move-up";
                    }
                    else {
                        moveCSS = "hide-help-bottom";
                    }
                }
            }
        }

        if (helpNode !== null) {
            if (helpNode.classList.contains("show-help-move-up") && !hasHelp) {
                moveCSS = "hide-help";
            }
        }

        this.previousHadHelp = hasHelp;
        this.previousSectionNo = this.props.data.menuSectionNo;
        this.previousSubSectionNo = this.props.data.menuSubSectionNo;

        if (!exists) {
            if (hasHelp) {
                setTimeout(() => {
                    let node = document.querySelector(".help-btn-container");

                    if (node !== null) {
                        node.classList.add("has-help");
                    }
                }, 50)
            }
        }

        return  <div className={ "help-container " + helpCustomClass }>
                    <div style={{ display : this.props.data.forceHideHelpButton ? "none" : "block" }} className={"help-btn-container btn-" + helpCustomClass + " " + (exists ? (moveCSS !== null ? moveCSS : hasHelp ? "has-help" : "") : "")}>
                        <Button onClick={() => this.toggleHelpView()}>
                            <img src={"/images/icons/btn-help.png"} alt="png" />
                        </Button>
                    </div>

                    {
                        this.props.data.showHelp ?
                            <div className={"help-text-container " + (helpImage !== null && helpImage !== ""  && helpImage !== undefined ? "help-container-with-image" : "") }>
                                <div className="help-text-bg">
                                    <div className="help-close-btn">
                                        <Button onClick={() => this.toggleHelpView()}>
                                            <img src={"/images/transparent.png"} alt="png" />
                                        </Button>
                                    </div>
                                    <div className="help-title unselectable"  dangerouslySetInnerHTML={ { __html : "<div>" + helpTitle + "</div>" } } />
                                    <div className="help-text unselectable" dangerouslySetInnerHTML={ { __html : helpText }} />
                                </div>
                                {
                                    helpImage !== null && helpImage !== "" && helpImage !== undefined ?
                                        <div>
                                            <div className="help-text-image transparent-button" style={{zIndex:5, width:"390px", height:"300px"}}>
                                                <Button onClick={() => this.showBigImage("/images/pois/" + helpImage.replace("_small", "_big"))}>
                                                    <img src={"/images/transparent.png"} alt="png" />
                                                </Button>
                                            </div>
                                            <div className="help-text-image">
                                                <img src={"/images/pois/" + helpImage } alt="png" />
                                            </div>
                                        </div>
                                    :
                                        null
                                }
                                {
                                    helpImage2 !== null && helpImage2 !== "" && helpImage2 !== undefined ?
                                        <div className='help-text-image2-section'>
                                            <div className="help-text-image transparent-button" style={{zIndex:5, width:"390px", height:"300px"}}>
                                                <Button onClick={() => this.showBigImage("/images/pois/" + helpImage2.replace("_small", "_big"))}>
                                                    <img src={"/images/transparent.png"} alt="png" />
                                                </Button>
                                            </div>
                                            <div className="help-text-image">
                                                <img src={"/images/pois/" + helpImage2 } alt="png" />
                                            </div>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                        :
                            null
                    }

                    {
                        this.state.bigImage !== null ?
                            <div className="big-image">
                                <Button onClick={() => this.hideBigImage()}>
                                    <img src={this.state.bigImage} alt="png" />
                                </Button>
                            </div>
                        :
                            null
                    }
                </div>
    }
}));