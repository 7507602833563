const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">
<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>L'Anse-à-Valleau</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>779.35</X>
<Y>992.95</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Montagne-Sèche</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>772.3</X>
<Y>992.95</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Gros-Morne</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>747.35</X>
<Y>995.95</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Mont-Rothery</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>750.25</X>
<Y>1004.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Mont-Louis</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>737.15</X>
<Y>999.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>New Richmond</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>750.25</X>
<Y>1061.85</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Carleton</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>740.3</X>
<Y>1065.95</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Mesgi'g Ugju's'n</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>723.35</X>
<Y>1062.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Vents du Kempt</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>715.35</X>
<Y>1056.85</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Saint-Ulric–Saint-Léandre</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>696.2</X>
<Y>1027.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Baie-des-Sables</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>685.25</X>
<Y>1035.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Saint-Damase</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>685.25</X>
<Y>1039.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Lac-Alfred</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>697.15</X>
<Y>1044.95</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>La Mitis</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>697.2</X>
<Y>1050.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Plateau-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>699</X>
<Y>1060.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Roncevaux</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>701</X>
<Y>1061.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Plateau</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>703</X>
<Y>1063.9</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Viger-Denonville</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>621.0</X>
<Y>1131.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Témiscouata</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>623.0</X>
<Y>1150.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Témiscouata II</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>621.0</X>
<Y>1152.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Rivière-du-Moulin</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>509.7</X>
<Y>1144.75</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Seigneurie-de-Beaupré-2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>544.0</X>
<Y>1188.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Seigneurie-de-Beaupré-3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>541.0</X>
<Y>1190.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Côte-de-Beaupré</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>548.0</X>
<Y>1192.5</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Seigneurie-de-Beaupré-4</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>542.0</X>
<Y>1195.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Saint-Philémon</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>594.35</X>
<Y>1234.05</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Massif-du-Sud</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>590.35</X>
<Y>1237.05</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Frampton</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>575.35</X>
<Y>1254.05</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Granit</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>603.05</X>
<Y>1309.25</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Saint-Robert-Bellarmin</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>605.05</X>
<Y>1311.25</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Moulins</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>547.05</X>
<Y>1284.25</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>L'Érable</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>544.05</X>
<Y>1287.25</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Pierre-De Saurel</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>486.0</X>
<Y>1327.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<SOUS-TYPE></SOUS-TYPE>
<NAME>Montérégie</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>471.0</X>
<Y>1383.0</Y>
<CONSTR>false</CONSTR>
</centrale>

<centrale>
<ID>L1</ID>
<TYPE>Eolienne</TYPE>
<NAME>Parc éolien Dune-du-Nord</NAME>
<X>923.3</X>
<Y>1060.5</Y>
</centrale>

</dataroot> `
export default xml;