const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>I1</ID>
<TYPE>Transport</TYPE>
<SOUS-TYPE>Interconnexions</SOUS-TYPE>
<!-- <NAME>Le centre d’Outaouias</NAME> -->
<NAME>Le poste de l’Outaouais</NAME>
<TITRE>Le poste de l’Outaouais est un poste redresseur-onduleur qui permet de synchroniser les fréquences de deux réseaux voisins.</TITRE>
<IMAGE>i1_small.jpg</IMAGE>
<X>347</X>
<Y>1403</Y>
<SCALE>1.3</SCALE>
<ANGLE>-15.2</ANGLE>
<CLICKABLE>true</CLICKABLE>
</centrale>

</dataroot> `
export default xml;
