import React from 'react';
import { observer, inject } from "mobx-react";
import { SCREENSAVER_DELAY } from '../constants';
import Button from "./button";
import SectionMenu from "./section-menu";
import historyText from "../texts/history.json";
import productionText from "../texts/production.json";
import transportText from "../texts/transport.json";
import autochtoneText from "../texts/autochtone.json";
import XMLParser from 'react-xml-parser';

import transportInterconnections from "../pois/transport_interconnections";

import history1878 from "../pois/history_1878";
import history1905 from "../pois/history_1905";
import history1945 from "../pois/history_1945";

import productionHydro from "../pois/production_hydro";
import productionThermal from "../pois/production_therm";
import productionEolien from "../pois/production_eolien";
import productionSolar from "../pois/production_solar";
import productionCoteNord from "../pois/production_cote_nord";

export default function MainHOC(Component) {
	class PageHOC extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				resetTime : new Date(),
				shownPoi : null,
				bigImage : null
			}

			this.screensaverTimeoutId = null;
			this.screensaverDelay = SCREENSAVER_DELAY;
		}

		componentDidMount() {
			document.title = this.props.displayName != null ? this.props.displayName : this.props.pageName;
			this.enableScreensaver();
		}

		componentWillUnmount() {
			this.disableScreensaver();
		}

		checkActivity() {
			this.screenSaverTimeoutId = setTimeout(() => {
				this.screenSaverTimeoutId = null;
				if (new Date().getTime() - this.props.data.lastActivityTime > this.screensaverDelay) {
					this.props.data.onScreenSaver = true;
					this.props.data.forcedHiddenMenu = false;
				}
				else {
					this.checkActivity();
				}
			}, 15 * 1000);
		}

		enableScreensaver() {
			if (this.screenSaverTimeoutId == null) {
				this.props.data.lastActivityTime = new Date().getTime();
				this.checkActivity();
			}
		}

		disableScreensaver() {
			if (this.screenSaverTimeoutId != null) {
				clearTimeout(this.screenSaverTimeoutId);
				this.screenSaverTimeoutId = null;
			}
		}

		screenClicked() {
			if (this.props.data.onScreenSaver) {
				this.goTo("/");
				this.props.data.lastActivityTime = new Date().getTime();
				this.props.data.onScreenSaver = false;
				this.enableScreensaver();
			}
		}

		changeSection(no, noSub) {
			this.props.data.showHelp = false;
			this.props.data.menuSectionNo = no;
			this.props.data.menuSubSectionNo = noSub;
			this.props.data.forceHideHelpButton = false;
			this.props.data.forcedMainTitle = null;

			this.updatePois();
			this.setState({
				shownPoi : null,
				bigImage : null
			})
		}

		updatePois(path = null) {
			let pois = null;
			let currentPath = path;

			if (currentPath === null) {
				currentPath = this.props.location.pathname;
			}

			if (currentPath === "/history") {
				if (this.props.data.menuSectionNo === -1) {
					pois = [history1878, history1905, history1945];
				}
				else if (this.props.data.menuSectionNo === 0) {
					pois = [history1878];
				}
				else if (this.props.data.menuSectionNo === 1) {
					pois = [history1905];
				}
				else if (this.props.data.menuSectionNo === 2) {
					pois = [history1945];
				}
			}
			else if (currentPath === "/production") {
				if (this.props.data.menuSectionNo === -1) {
					pois = [productionHydro, productionEolien, productionThermal, productionSolar];
				}
				else if (this.props.data.menuSectionNo === 0) {
					pois = [productionHydro];

					if (this.props.data.menuSubSectionNo === 3) {
						pois = [productionCoteNord];
					}
				}
				else if (this.props.data.menuSectionNo === 1) {
					pois = [productionThermal];
				}
				else if (this.props.data.menuSectionNo === 2) {
					pois = [productionEolien];
				}
				else if (this.props.data.menuSectionNo === 3) {
					pois = [productionSolar];
				}
			}
			else if (currentPath === "/transport") {
				if (this.props.data.menuSectionNo === 2) {
					pois = [transportInterconnections];
				}
			}

			if (pois === null) {
				this.props.data.pois = null;
			}
			else {
				let tmp = [];

				pois.forEach((p, idx) => {
					let xml = new XMLParser().parseFromString(p);

					if (currentPath === "/production") {
						if (this.props.data.menuSectionNo === -1) {
							if (idx === 2) {
								for (let i = 0; i < xml.children.length; i++) {
									let child = xml.children[i];

									child.children.forEach(c => {
										if (c.name === "SOUS-TYPE" && c.value === "Diesel") {
											xml.children.splice(i, 1);
											i--;
										}
									})
								}
							}
						}
						else if (this.props.data.menuSectionNo === 0) {
							if (this.props.data.menuSubSectionNo === 0) {
								for (let i = 0; i < xml.children.length; i++) {
									let child = xml.children[i];

									child.children.forEach(c => {
										if (c.name === "SOUS-TYPE" && c.value !== "Au fil de l’eau") {
											xml.children.splice(i, 1);
											i--;
										}
									})
								}
							}
							else if (this.props.data.menuSubSectionNo === 1) {
								for (let i = 0; i < xml.children.length; i++) {
									let child = xml.children[i];

									child.children.forEach(c => {
										if (c.name === "SOUS-TYPE" && c.value !== "À réservoir") {
											xml.children.splice(i, 1);
											i--;
										}
									})
								}
							}
						}
						else if (this.props.data.menuSectionNo === 1) {
							if (this.props.data.menuSubSectionNo === 0) {
								for (let i = 0; i < xml.children.length; i++) {
									let child = xml.children[i];

									child.children.forEach(c => {
										if (c.name === "SOUS-TYPE" && c.value !== "Diesel") {
											xml.children.splice(i, 1);
											i--;
										}
									})
								}
							}
						}
					}

					tmp.push(xml);
				});

				this.props.data.pois = tmp;
			}
		}

		goTo(path) {
			if (this.props.location.pathname !== path) {
				this.props.data.previousPath = this.props.location.pathname;
				let text = null;

				if (path === "/transport") {
					text = transportText;
				}
				else if (path === "/history") {
					text = historyText;
				}
				else if (path === "/production") {
					text = productionText;
				}
				else if (path === "/autochtone") {
					text = autochtoneText;
				}

				this.props.data.text = text;
				this.changeSection(-1, -1);
				this.props.history.push(path);
			}
			else {
				this.changeSection(-1, -1);
			}

			this.updatePois(path)
			this.closeShownPoi();
		}

		poiClicked(poi, title, text, imageName) {
			this.setState({
				shownPoi : {
					poi : poi,
					title : title,
					text : text,
					imageName : imageName
				}
			})
		}

		closeShownPoi() {
			this.setState({
				shownPoi : null
			})
		}

		showBigImage(name) {
			const img = new Image();
			img.onload = () => {
				this.setState({
					bigImage : name
				})
			}
			img.onerror = () => {
				if (name.indexOf(".jpg")) {
					name = name.replace(".jpg", ".png");
					this.showBigImage(name)
				}
			}

			img.src = name;
		}

		hideBigImage() {
			this.setState({
				bigImage : null
			})
		}

		render() {
			let sectionTitle = "";

			if (this.props.data.text !== null) {
				if (this.props.data.menuSectionNo === -1) {
					sectionTitle = this.props.data.text.mainTitle !== null ? this.props.data.text.mainTitle : null;
				}
				else {
					if (this.props.data.text.sections[this.props.data.menuSectionNo].mainTitle !== null) {
						sectionTitle = this.props.data.text.sections[this.props.data.menuSectionNo].mainTitle;
					}

					if (this.props.data.menuSubSectionNo !== -1) {
						sectionTitle = this.props.data.text.sections[this.props.data.menuSectionNo].subSections[this.props.data.menuSubSectionNo].mainTitle;
					}
				}
			}

			return <div onClick={ () => this.screenClicked() }>
						<Component {...this.props} goTo={p => this.goTo(p)} enableScreensaver={() => this.enableScreensaver()} disableScreensaver={() => this.disableScreensaver()} />

						{
							!this.props.data.onScreenSaver && !this.props.data.forcedHiddenMenu ?
								<nav className="primary-nav">
									<Button onClick={() => this.goTo("/production")}>
										<img src={"/images/btn-production.gif"} alt="gif" />
									</Button>
									<Button onClick={() => this.goTo("/transport")}>
										<img src={"/images/btn-transport.gif"} alt="gif" />
									</Button>
									<Button onClick={() => this.goTo("/history")}>
										<img src={"/images/btn-history.gif"} alt="gif" />
									</Button>
									<Button onClick={() => this.goTo("/autochtone")}>
										<img src={"/images/btn-autochtone.gif"} alt="gif" />
									</Button>
									<Button onClick={() => this.goTo("/")}>
										<img src={"/images/btn-home.png"} alt="png" />
									</Button>
								</nav>
							:
								null
						}

						{
							this.props.data.forcedMainTitle !== null && this.props.data.forcedMainTitle !== "" && this.props.data.forcedMainTitle !== undefined ?
								<h2 className="unselectable">
									{this.props.data.forcedMainTitle}
								</h2>
							:
							sectionTitle !== null && sectionTitle !== "" && sectionTitle !== undefined ?
								<h2 className="unselectable" dangerouslySetInnerHTML={{__html : sectionTitle}}>
								</h2>
							:
								null
						}

						{
							this.props.data.text !== null ?
								<SectionMenu onClick={(no, noSub) => {this.changeSection(no, noSub)}} />
							:
								null
						}

						{
							this.props.data.pois !== null ?
								<div className="pois">
									{
										this.props.data.pois.map((pois, idx) => {
											return pois.children.map(poi => {
												let x = 0;
												let y = 0;
												let name = "name";
												let canClick = false;
												let imagePath = null;
												let text = null;
												let type = null;
												let isActive = true;
												let isInConstruction = false;

												poi.children.forEach(child => {
													if (child.name === "X") x = parseInt(child.value);
													if (child.name === "Y") y = parseInt(child.value);
													if (child.name === "NAME") name = child.value;
													if (child.name === "CLICKABLE" && child.value === "true") canClick = true;
													if (child.name === "TITRE") text = child.value;
													if (child.name === "IMAGE") imagePath = child.value;
													if (child.name === "SOUS-TYPE") type = child.value;
													if (child.name === "STATUT" && child.value === "Inactif") isActive = false;
													if (child.name === "CONSTR" && child.value === "true") isInConstruction = true;
												})

												if (this.props.location.pathname === "/production" && this.props.data.menuSubSectionNo === -1) {
													canClick = false;
												}

												return <div className={"poi pois-section-" + idx} key={idx + "-" + name} style={{left : x + "px", top : y + "px"}}>
															<Button onClick={() => canClick ? this.poiClicked(poi, name, text, imagePath) : null} >
																{
																	this.props.location.pathname === "/production" ?
																		this.props.data.menuSectionNo === -1 ?
																			idx === 0 ?
																				isInConstruction ?
																					<img src="/images/icons/icon_hydro_construction_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																				:
																				type === "Au fil de l’eau" ?
																					<img src="/images/icons/icon_hydro1_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																				:
																					<img src="/images/icons/icon_hydro2_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			idx === 1 ?
																				<img src="/images/icons/icon_wind_turbine_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			idx === 2 ?
																				<img src="/images/icons/icon_thermal_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			idx === 3 ?
																				<img src="/images/icons/icon_solar_panel.png" style={{top: "-12px", left:"-12px"}} height="18" alt="png"/>
																			:
																				null
																		:
																		this.props.data.menuSectionNo === 0 ?
																			idx === 0 || idx === 3?
																				isInConstruction ?
																				    canClick ?
																					    <img src="/images/icons/icon_hydro_construction.png" style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																					:
																					    <img src="/images/icons/icon_hydro_construction_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																				:
																				type === "Au fil de l’eau" ?
																					canClick && this.props.data.menuSubSectionNo !== 2 ?
																						<img src="/images/icons/icon_hydro1_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px", zIndex : 2}} height="40" alt="png"/>
																					:
																						<img src="/images/icons/icon_hydro1_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																				:
																					canClick && this.props.data.menuSubSectionNo !== 2 ?
																						<img src="/images/icons/icon_hydro2_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																					:
																						<img src="/images/icons/icon_hydro2_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			idx === 1 ?
																				canClick ?
																					<img src="/images/icons/icon_hydro2_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																				:
																					<img src="/images/icons/icon_hydro2_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			idx === 2 ?
																				<img src="/images/icons/icon_thermal_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																				null
																		:
																		this.props.data.menuSectionNo === 1 ?
																			canClick && this.props.data.menuSubSectionNo === 0 ?
																				<img src="/images/icons/icon_thermal_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-30px", left:"-30px", filter: "none"}} height="60" alt="png"/>
																			:
																			isActive ?
																				<img src="/images/icons/icon_thermal_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																				:
																				<img src="/images/icons/icon_thermal_inactive_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																		:
																		this.props.data.menuSectionNo === 2 ?
																			<img src="/images/icons/icon_wind_turbine_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																		:
																		this.props.data.menuSectionNo === 3 ?
																			<img src="/images/icons/icon_solar_panel.png" style={{top: "-12px", left:"-12px"}} height="18" alt="png"/>
																		:
																			null
																	:
																	this.props.location.pathname === "/transport" ?
																			canClick ?
																				<img src="/images/icons/icon_transport_interconnection.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{transform: "rotate(-20deg)", top: "-25px", left:"-20px"}} height="49" alt="png"/>
																			:
																				<img src="/images/icons/icon_transport_interconnection_sm.png" style={{transform: "rotate(-20deg)", top: "-12px", left:"-12px"}} height="24" alt="png"/>
																	:
																	this.props.location.pathname === "/history" ?
																			type === "En service" ?
																				canClick && this.props.data.menuSectionNo !== -1 ?
																					<img src="/images/icons/icon_history_on_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																				:
																					<img src="/images/icons/icon_history_on_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																			type === "En construction" ?
																				canClick && this.props.data.menuSectionNo !== -1 ?
																					<img src="/images/icons/icon_history_construction.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																				:
																					<img src="/images/icons/icon_history_construction_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																			:
																				canClick && this.props.data.menuSectionNo !== -1 ?
																					<img src="/images/icons/icon_history_off_clickable.png" className={"clickable " + (this.state.shownPoi !== null && this.state.shownPoi.poi === poi ? "clicked" : "")} style={{top: "-20px", left:"-20px"}} height="40" alt="png"/>
																				:
																					<img src="/images/icons/icon_history_off_sm.png" style={{top: "-12px", left:"-12px"}} height="24" alt="png"/>
																	:
																		"?"
																}
															</Button>
														</div>
											})
										})
									}
								</div>
							:
								null
						}

						{
							this.state.shownPoi !== null ?
								<div className="shown-poi">
									<div className={"shown-pois-content " + (this.state.shownPoi.imageName === null || this.state.shownPoi.imageName === "" ? "shown-pois-content-no-image" : "")}>
										<div className="shown-poi-title unselectable" dangerouslySetInnerHTML={{__html : this.state.shownPoi.title.replace(/\|\|\|/g, "<div style='height:2px'></div>")}}></div>
										<div className="shown-poi-text unselectable" dangerouslySetInnerHTML={ {__html : this.state.shownPoi.text.replace(/\|\|\|/g, "<div style='height:2px'></div>").replace(/\|\|/g, "<div style='height:10px'></div>")} }></div>
										<div className="shown-poi-close-btn">
											<Button onClick={() => this.closeShownPoi()}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
									</div>
									{
										this.state.shownPoi.imageName !== null && this.state.shownPoi.imageName !== "" ?
											<div>
												<div className="shown-poi-image transparent-button" style={{zIndex:5, width:"390px", height:"300px"}}>
													<Button onClick={() => this.showBigImage("/images/pois/" + this.state.shownPoi.imageName.replace("_small", "_big"))}>
														<img src={"/images/transparent.png"} alt="png" />
													</Button>
												</div>
												<div className="shown-poi-image">
													<img src={"/images/pois/" + this.state.shownPoi.imageName} alt="png" />
												</div>
											</div>
										:
											null
									}
								</div>
							:
								null
						}

						{
							this.state.bigImage !== null ?
								<div className="big-image"  onClick={() => this.hideBigImage()}>
									<img src={this.state.bigImage} alt="png" />
								</div>
							:
								null
						}


						{
							this.props.data.onScreenSaver ?
								<div>
									<video className="screensaver"
										src={"/videos/screensaver.mp4"}
										muted autoPlay loop
									></video>
								</div>
							:
								null
						}
				   </div>
		}
	}

	return inject("data")(observer(PageHOC));
}