const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>GG2</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Saint-Gabriel</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>536</X>
<Y>1242</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG3</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Little A</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>478.8</X>
<Y>1276.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG4</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Northern Aluminum Company</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>478.8</X>
<Y>1276.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG5</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Shawinigan-1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>476.9</X>
<Y>1276.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG6</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Montmorency</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>553</X>
<Y>1231.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG7</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Sault-Montmorency</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>553</X>
<Y>1231.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG8</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Chaudière</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>550</X>
<Y>1252.2</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG9</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Disarell</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>550.4</X>
<Y>1300.7</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG10</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Lachine</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>462.2</X>
<Y>1373</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG11</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Chambly</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>473.5</X>
<Y>1372</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG12</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Table Falls</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>383.3</X>
<Y>1382.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG13</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Hull 1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>323.3</X>
<Y>1405.1</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>GG14</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Deschênes</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>344.6</X>
<Y>1403.2</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>


</dataroot> `
export default xml;