import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Button from "../components/button";

export default inject("data")(observer(class Production extends Component {

	constructor(props) {
		super(props);

		this.state = {
            bigImage : null,
			viewNo : 0,
			showWaterCycle : true,
			showHelp : false,
			prodSite1 : false,
			prodSite2 : false,
			prodSite3 : false,
			prodSite4 : false,
			prodSite5 : false,
			prodSite6 : false,
			prodSite7 : false,
		}
	}

	toggleView() {
		this.setState({
			viewNo : this.state.viewNo > 0 ? 0 : 1,
			showHelp : false,
			prodSite1 : false,
			prodSite2 : false,
			prodSite3 : false,
			prodSite4 : false,
			prodSite5 : false,
			prodSite6 : false,
			prodSite7 : false,
		}, () => {
			if (this.state.viewNo > 0) {
				this.props.data.forcedMainTitle = "La production d'électricité au Canada et aux États-Unis";
			}
			else {
				this.props.data.forcedMainTitle = null;
			}
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.resetTime !== this.props.resetTime) {
			this.setState({
				viewNo : 0,
				showWaterCycle : true,
				showHelp : false,
				prodSite1 : false,
				prodSite2 : false,
				prodSite3 : false,
				prodSite4 : false,
				prodSite5 : false,
				prodSite6 : false,
				prodSite7 : false,
			});
		}
	}

	closeShownPoi() {
		this.setState({
			showWaterCycle : false
		})
	}

	toggleHelpView() {
		this.setState({
			showHelp : !this.state.showHelp
		})
	}

	changeView(no) {
		this.setState({
			viewNo : no,
			showHelp : false,
			prodSite1 : false,
			prodSite2 : false,
			prodSite3 : false,
			prodSite4 : false,
			prodSite5 : false,
			prodSite6 : false,
			prodSite7 : false,
		})
	}

	showBigImage(name) {
        const img = new Image();
        img.onload = () => {
            this.setState({
                bigImage : name
            })
        }
        img.onerror = () => {
            if (name.indexOf(".jpg")) {
                name = name.replace(".jpg", ".png");
                this.showBigImage(name)
            }
        }

        img.src = name;
    }

    hideBigImage() {
        this.setState({
            bigImage : null
        })
    }


	render() {
		return (
			<section className={"prod-" + this.props.data.menuSectionNo + "-" + this.props.data.menuSubSectionNo}>
				<div>
					{
						this.state.viewNo === 0 ?
							<div>
								{
									this.props.data.menuSectionNo === 0 && this.props.data.menuSubSectionNo === 2 && this.state.showWaterCycle ?
										<div className="shown-poi poi-water" style={{top : "380px"}}>
											<div className="shown-pois-content shown-pois-content-bg-2">
												<div className="shown-poi-title unselectable">
													Le cycle de l'eau
												</div>
												<div className="shown-poi-text unselectable" style={{fontSize : "18px", lineHeight : "1em", width: "45%", height:"175px"}}>
													En réchauffant les océans, les lacs et les rivières, le Soleil provoque l'évaporation de l'eau. Les vapeurs
													d'eau s'élèvent dans l'atmosphère où elles se condensent et se transforment en nuages. Quand la condensation
													(ou l'humidité) atteint son maximum, les nuages laissent alors échapper des gouttelettes d'eau qui retombent sous forme
													de pluie ou de neige. L'eau revient donc sur la terre, retourne vers les lacs, les rivières et les océans, puis le cycle recommence.
												</div>
												<div className="shown-poi-close-btn">
													<Button onClick={() => this.closeShownPoi()}>
														<img src={"/images/transparent.png"} alt="png" />
													</Button>
												</div>
											</div>
											<div className="shown-poi-image transparent-button" style={{zIndex:5, width:"390px", height:"300px"}}>
                                                <Button onClick={() => this.showBigImage("/images/pois/p1_big.jpg")}>
                                                    <img src={"/images/transparent.png"} alt="png" />
                                                </Button>
                                            </div>
											<div className="shown-poi-image shown-poi-image-bg-2">
												<img src={"/images/pois/p1_small.jpg"} alt="png" />
											</div>
										</div>
									:
									this.props.data.menuSectionNo === 2 && this.props.data.menuSubSectionNo === 0 ?
										<div className="video-container"
											onClick={() => { this.props.data.menuSubSectionNo = -1; } }>
											<video
												src={"/videos/wind_turbines.mp4"}
												muted autoPlay loop
											></video>
										</div>
									:
										null
								}
							</div>
						:
						this.state.viewNo === 1 ?
							<div className="production-view-2">
								<Button onClick={() => this.changeView(2)}>
									<img src={"/images/transparent.png"} alt="png" />
								</Button>
								<div className="help-container">
									<div className={"help-btn-container has-help"}>
										<Button onClick={() => this.toggleHelpView()}>
											<img src={"/images/icons/btn-help.png"} alt="png" />
										</Button>
									</div>
								</div>
								{
									this.state.showHelp ?
										<div className="stats-container">
											<Button onClick={() => this.setState({showHelp : false})}>

											</Button>
											<div>
												<img src={"/images/production_stats.png"} alt="png" />
											</div>
										</div>
									:
										<div className="energy-container">
											<div className="energy-menu">
												<div className="energy-line">
													<Button customClass={this.state.prodSite1 ? "energy-site-selected energy-site1" : ""} onClick={() => { this.setState({prodSite1 : !this.state.prodSite1}) }}>
														Hydroélectrique
													</Button>
													<Button customClass={this.state.prodSite2 ? "energy-site-selected energy-site2" : ""} onClick={() => { this.setState({prodSite2 : !this.state.prodSite2}) }}>
														Pétrole
													</Button>
												</div>
												<div className="energy-line">
													<Button customClass={this.state.prodSite3 ? "energy-site-selected energy-site3" : ""} onClick={() => { this.setState({prodSite3 : !this.state.prodSite3}) }}>
														Nucléaire
													</Button>
													<Button customClass={this.state.prodSite4 ? "energy-site-selected energy-site4" : ""} onClick={() => { this.setState({prodSite4 : !this.state.prodSite4}) }}>
														Charbon
													</Button>
												</div>
												<div className="energy-line">
													<Button customClass={this.state.prodSite5 ? "energy-site-selected energy-site5" : ""} onClick={() => { this.setState({prodSite5 : !this.state.prodSite5}) }}>
														Éolien
													</Button>
													<Button customClass={this.state.prodSite6 ? "energy-site-selected energy-site6" : ""} onClick={() => { this.setState({prodSite6 : !this.state.prodSite6}) }}>
														Gaz naturel
													</Button>
												</div>
												<div className="energy-line">
													<Button customClass={this.state.prodSite7 ? "energy-site-selected energy-site7" : ""} onClick={() => { this.setState({prodSite7 : !this.state.prodSite7}) }}>
														Solaire
													</Button>
												</div>
											</div>
											{
												this.state.prodSite1 ?
													<div className="prod-filter prod-filter-1"></div>
												:
													null
											}
											{
												this.state.prodSite2 ?
													<div className="prod-filter prod-filter-2"></div>
												:
													null
											}
											{
												this.state.prodSite3 ?
													<div className="prod-filter prod-filter-3"></div>
												:
													null
											}
											{
												this.state.prodSite4 ?
													<div className="prod-filter prod-filter-4"></div>
												:
													null
											}
											{
												this.state.prodSite5 ?
													<div className="prod-filter prod-filter-5"></div>
												:
													null
											}
											{
												this.state.prodSite6 ?
													<div className="prod-filter prod-filter-6"></div>
												:
													null
											}
											{
												this.state.prodSite7 ?
													<div className="prod-filter prod-filter-7"></div>
												:
													null
											}
										</div>
								}
							</div>
						:
						this.state.viewNo === 2 ?
							<div className="production-view-3">
								<div className="main-winds"></div>
								<Button onClick={() => this.changeView(1)}>
									<img src={"/images/transparent.png"} alt="png" />
								</Button>
								<div className="help-container">
									<div className={"help-btn-container has-help"}>
										<Button onClick={() => this.toggleHelpView()}>
											<img src={"/images/icons/btn-help.png"} alt="png" />
										</Button>
									</div>
								</div>
								{
									this.state.showHelp ?
										<div className="shown-poi" style={{top : "120px"}}>
											<div className="shown-pois-content shown-pois-content-bg-3">
												<div className="shown-poi-title unselectable">
													Vent dominant
												</div>
												<div className="shown-poi-text unselectable">
													Les centrales utilisant le charbon, le pétrole ou le gaz naturel comme source de production
													émettent une quantité importante de gaz à effet de serre qui sont emportés par les grands vents
													dominants d'Amérique du Nord au-dessus du Québec.
												</div>
												<div className="shown-poi-close-btn">
													<Button onClick={() => this.toggleHelpView()}>
														<img src={"/images/transparent.png"} alt="png" />
													</Button>
												</div>
											</div>
										</div>
										:
										null
								}
							</div>
						:
							null
					}

					{
						this.state.bigImage !== null ?
							<div>
								{
									this.state.bigImage === "/images/pois/p1_big.jpg" ?
									<div className="video-container"
										onClick={() => this.hideBigImage() }>
										<video
											src={"/videos/water_cycle.mp4"}
											muted autoPlay
										></video>
									</div>
									:
									<div className="big-image">
										<Button onClick={() => this.hideBigImage()}>
											<img src={this.state.bigImage} alt="png" />
										</Button>
									</div>
								}
							</div>
						:
							null
					}

					<div className="zoom-container" style={{display: this.state.viewNo === 1 && this.state.showHelp ? "none" : "block"}}>
						{
							this.state.viewNo === 0 ?
								<Button onClick={() => this.toggleView() }>
									<img src="/images/icons/btn-zoom-out.png" alt="png" />
								</Button>
							:
								<Button onClick={() => this.toggleView() }>
									<img src="/images/icons/btn-zoom-in.png" alt="png" />
								</Button>
						}
					</div>
				</div>
			</section>
		);
  	}
}));