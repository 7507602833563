import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";

export default inject("data")(observer(class History extends Component {

	render() {
		return (
			<section>

			</section>
		);
  	}
}));