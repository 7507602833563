import React, { Component} from 'react';
import { observer, inject } from "mobx-react";

export default inject("data")(observer(class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active : false
        }
    }

    buttonClicked() {
        this.props.data.lastActivityTime = new Date().getTime();
        this.props.onClick();
    }

    render() {
        return <button
                    className={(this.props.customClass !== undefined ? this.props.customClass : "btn") + " " + (this.state.active ? this.props.customActiveClass !== undefined ? this.props.customActiveClass : "btn-active" : "") }
                    onMouseDown={() => this.setState({active:true})}
                    onMouseOut={() => this.setState({active:false})}
                    onMouseUp={() => this.setState({active:false})}
                    type="button"
                    style={this.props.hide ? {opacity : 0} : null}
                    onClick={() => this.buttonClicked()}>
                    {this.props.children}
                </button>;
    }
}));