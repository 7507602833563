const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>O1</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>À turbines à gaz</SOUS-TYPE>
<NAME>Bécancour</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>500.1</X>
<Y>1292.1</Y>
</centrale>

<centrale>
<ID>O3</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>À turbines à gaz</SOUS-TYPE>
<NAME>Cadillac</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>165.4</X>
<Y>1219.5</Y>
</centrale>

<centrale>
<ID>P1</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Ivujivik</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>24.7</X>
<Y>268.4</Y>
</centrale>

<centrale>
<ID>P2</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Salluit</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>90.5</X>
<Y>276.6</Y>
</centrale>

<centrale>
<ID>P3</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Kangiqsujuaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>217.7</X>
<Y>295.6</Y>
</centrale>

<centrale>
<ID>P4</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Quaqtaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>306.1</X>
<Y>316.4</Y>
</centrale>

<centrale>
<ID>P5</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Kangirsuk</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>303.4</X>
<Y>369.5</Y>
</centrale>

<centrale>
<ID>P6</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Aupaluk</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>342.8</X>
<Y>409.1</Y>
</centrale>

<centrale>
<ID>P7</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Tasiujaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>344.8</X>
<Y>445.6</Y>
</centrale>

<centrale>
<ID>P8</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Akulivik</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>28.9</X>
<Y>355.0</Y>
</centrale>

<centrale>
<ID>P9</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Puvirnituq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>58.3</X>
<Y>396.6</Y>
</centrale>

<centrale>
<ID>P10</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Inukjuak</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>42.9</X>
<Y>489.9</Y>
</centrale>

<centrale>
<ID>P11</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Umiujaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>138.8</X>
<Y>606.8</Y>
</centrale>

<centrale>
<ID>P12</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Kuujjuaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>407.5</X>
<Y>466.8</Y>
</centrale>

<centrale>
<ID>P13</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Kangiqsualujjuaq</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>472.3</X>
<Y>417.0</Y>
</centrale>

<centrale>
<ID>P14</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Kuujjuarapik Whapmagoostui</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>106.7</X>
<Y>699.3</Y>
</centrale>

<centrale>
<ID>P15</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Clova</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>318.6</X>
<Y>1190.5</Y>
</centrale>

<centrale>
<ID>P16</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Obedjiwan</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>334.9</X>
<Y>1147.6</Y>
</centrale>

<centrale>
<ID>P18</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Port-Menier</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>774.6</X>
<Y>941.9</Y>
</centrale>

<centrale>
<ID>P19</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>La Romaine</NAME>
<STATUT>Inactif</STATUT>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>818.1</X>
<Y>884.2</Y>
</centrale>

<centrale>
<ID>P20</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>La Tabatière</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>906.4</X>
<Y>843.0</Y>
</centrale>

<centrale>
<ID>P21</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Saint-Augustin</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>916.3</X>
<Y>796.5</Y>
</centrale>

<centrale>
<ID>P22</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Blanc-Sablon</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>958.0</X>
<Y>751.1</Y>
</centrale>

<centrale>
<ID>P23</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>La centrale des Îles-de-la-Madeleine</NAME>
<TITRE>Mise en service en 1990, la centrale des Îles-de-la-Madeleine comporte six groupes moteurs diesels.
Type de moteur : V-16.</TITRE>
<IMAGE>p23_small.jpg</IMAGE>
<CLICKABLE>true</CLICKABLE>
<X>923.3</X>
<Y>1060.5</Y>
</centrale>

<centrale>
<ID>P24</ID>
<TYPE>Thermique</TYPE>
<SOUS-TYPE>Diesel</SOUS-TYPE>
<NAME>Centrale de L’île-d’Entrée</NAME>
<TITRE>La centrale de L’île-d’Entrée, construite en 1960, desserre une petite communauté isolée de 30 personnes à l’entrée sud-est de l’archipel. D’une puissance de 1,2 MW, la centrale est 55 fois plus petite que la centrale de Cap-aux-Meules. </TITRE>
<CLICKABLE>true</CLICKABLE>
<IMAGE></IMAGE>
<X>940.3</X>
<Y>1080.5</Y>
</centrale>

</dataroot> `
export default xml;
