const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>HH1</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>saint_elzear</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>776</X>
<Y>1060</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH2</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>mitis_1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>636.1</X>
<Y>1089.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH3</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>riviere_du_loup</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>608</X>
<Y>1139</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH4</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>chute_garneau</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>515</X>
<Y>1131</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH5</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>pont_arnaud</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>527</X>
<Y>1123</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH6</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>marches_naturelles</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>544</X>
<Y>1228</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH8</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Armagh</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>580</X>
<Y>1244</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH9</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Saint_Raphael</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>564</X>
<Y>1243</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH10</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Drummondville</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>478.4</X>
<Y>1354.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH11</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>saint_george_de_beauce</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>480</X>
<Y>1354</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH12</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>chute_burroughs</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>547</X>
<Y>1368</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH13</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Farhnam</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>482</X>
<Y>1377</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH14</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>saint_alban2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>532</X>
<Y>1238</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH15</ID>
<TYPE>Hydroélectrique
</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>saint_alban1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>532</X>
<Y>1238</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH16</ID>
<TYPE>Hydroélectrique
</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>saint_raymond_de_portneuf</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>524</X>
<Y>1239</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH18</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Powercourt</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>437</X>
<Y>1405</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH19</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>sainte_ursule</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>465</X>
<Y>1291</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH20</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>little_B</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>478</X>
<Y>1277</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH21</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>alcan_16</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>475</X>
<Y>1270</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH22</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapide_blanc</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>454</X>
<Y>1196</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH23</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>grand_mere</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>492</X>
<Y>1253</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH24</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_gabelle</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>491</X>
<Y>1287</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH25</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Proulxville</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>488</X>
<Y>1268</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH26</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>High_Falls</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>351</X>
<Y>1405</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH27</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Chute_Bell</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>378</X>
<Y>1371</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH28</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>isles_aux_chats</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>394</X>
<Y>1363</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH30</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>les_cedres</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>435</X>
<Y>1394</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH31</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>sainte_adele</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>417</X>
<Y>1341</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH32</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>chute_Wilson</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>435</X>
<Y>1348</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH34</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Saint_michel_des_saints</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>382</X>
<Y>1253</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH35</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Rawdon</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>442</X>
<Y>1322</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH36</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>La_Loutre</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>390</X>
<Y>1168</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH37</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Brown_Corporation</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>465</X>
<Y>1240</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH38</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>soulanges</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>430</X>
<Y>1398</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH40</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>corbeau</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>338</X>
<Y>1325</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH41</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>kipawa</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>156</X>
<Y>1341</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH42</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>La_Sarre1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>155</X>
<Y>1153</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH43</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>chute_hemmings</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>483</X>
<Y>1368</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH44</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>chelsea</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>351.2</X>
<Y>1379.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH45</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>paugan</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>331.5</X>
<Y>1367.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH46</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>bryson_rocher_fendu</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>296.2</X>
<Y>1391.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH47</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapides_des_quinze1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>148.9</X>
<Y>1277.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH48</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapide_7</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>209.4</X>
<Y>1253.1</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH49</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>hull_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>323.3</X>
<Y>1405.1</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>HH17</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>La centrale de Saint-Narcisse</NAME>
<TITRE>Mise en service en 1897, la centrale de Saint-Narcisse se distingue par son style architectural qui s’apparente à celui des moulins à eau du XIXe siècle.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>hh17_small.jpg</IMAGE>
<X>511.6</X>
<Y>1255.8</Y>
<CLICKABLE>false</CLICKABLE>
</centrale>

<centrale>
<ID>HH29</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale de Beauharnois</NAME>
<TITRE>Mise en service entre 1932 et 1961, la centrale de Beauharnois  compte le plus grand nombre de groupes turbines-alternateurs de tout le parc de production d’Hydro-Québec. Son style architectural s’inspire de l’Art déco.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>hh29_small.jpg</IMAGE>
<X>447.3</X>
<Y>1395.6</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>HH33</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale de la Rivière-des-Prairies</NAME>
<TITRE>Mise en service en 1929, la centrale de la Rivière-des-Prairies se caractérise par son architecture d’inspiration Art déco. En 2009, son enveloppe extérieure a été restaurée, mais les éléments architecturaux d’origine ont été respectés.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>hh33_small.jpg</IMAGE>
<X>455.8</X>
<Y>1355</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>HH39</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale des Rapides-Farmer</NAME>
<TITRE>Mise en service en 1927, la centrale des Rapides-Farmer possède une architecture de type rationaliste, typique des centrales construites par la Gatineau Power Company.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>hh39_small.jpg</IMAGE>
<X>353.1</X>
<Y>1392.8</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>HH50</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale de Shawinigan-2</NAME>
<TITRE>Mise en service en 1911, la centrale de Shawinigan-2 est la plus ancienne centrale d’Hydro-Québec qui fonctionne encore.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>hh50_small.jpg</IMAGE>
<X>476.9</X>
<Y>1276.9</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

</dataroot> `
export default xml;
