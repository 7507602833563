import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";

export default inject("data")(observer(class Home extends Component {

	render() {
		return (
			<section>
				<h1 className="unselectable">
					L'énergie électrique<br />au Québec
				</h1>
			</section>
		);
  	}
}));