const xml = `
<?xml version="1.0" encoding="UTF-8"?>
<dataroot xmlns:od="urn:schemas-microsoft-com:officedata" generated="2009-01-22T08:38:56">

<centrale>
<ID>KK2</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_grande_2a</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>118.1</X>
<Y>807.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK4</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_grande_3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>195.8</X>
<Y>802.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK5</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_grande_4</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>301.5</X>
<Y>770.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK6</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_forge_1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>363.7</X>
<Y>745.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK7</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>sarcelle</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>190</X>
<Y>878</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK8</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Centrale Bernard-Landry</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>210.3</X>
<Y>900.1</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK9</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Eastmain-1-A-Sarcelle-Rupert</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>228.9</X>
<Y>902.5</Y>
<CLICKABLE>non</CLICKABLE>
<CONSTR>true</CONSTR>
</centrale>

<centrale>
<ID>KK10</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Chute_fifty_foot</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>578</X>
<Y>859</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK42</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_romaine1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>784.0</X>
<Y>892.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK11</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_romaine2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>775.95</X>
<Y>866.25</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK43</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_romaine3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>761.0</X>
<Y>846.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK12</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>sainte_marguerite_3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>669</X>
<Y>905</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK13</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Magple</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>751</X>
<Y>909</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK15</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>toulnustouc</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>612.7</X>
<Y>979.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK16</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>outardes_4</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>581.2</X>
<Y>994.5</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK17</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>manic_3_rene_levesque</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>606.3</X>
<Y>1001.2</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK18</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>outardes_3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>589.8</X>
<Y>1005.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK20</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>manic_1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>621.6</X>
<Y>1033.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK21</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>outardes_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>620.8</X>
<Y>1047.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK22</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>peribonka</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>496</X>
<Y>1060</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK23</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>bersiamis_1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>563</X>
<Y>1042</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK24</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>bersiamis_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>589</X>
<Y>1045</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK25</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Gouin</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>384</X>
<Y>1167</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK26</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>petites_Bergeronnes2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>592</X>
<Y>1118</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK27</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>mitis_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>639.1</X>
<Y>1102.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK28</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapide_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>195</X>
<Y>1248</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK29</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>trenche</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>460.6</X>
<Y>1208.6</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK30</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>beaumont</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>462.6</X>
<Y>1223.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK31</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_tuque</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>463.6</X>
<Y>1240.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK32</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>Anse_saint_jean</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>545</X>
<Y>1228</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK34</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapides_des_iles</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>136.3</X>
<Y>1287.3</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK35</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>rapides_des_quinze2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>148.9</X>
<Y>1277.9</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK36</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>shawinigan_3</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>471.3</X>
<Y>1263.5</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK38</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_forge_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>416.9</X>
<Y>707.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK39</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>brisay</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>443.1</X>
<Y>712.0</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK40</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>la_grande_2</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>118.1</X>
<Y>807.8</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK41</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>Hors service</SOUS-TYPE>
<NAME>petites_Bergeronnes1</NAME>
<TITRE></TITRE>
<IMAGE></IMAGE>
<X>593</X>
<Y>1118</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK1</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale La Grande-1</NAME>
<TITRE>Mise en service entre 1993 et 1995, la centrale     La Grande-1 constitue le dernier aménagement hydroélectrique du complexe La Grande à turbiner l’eau avant qu’elle ne se jette dans la baie James.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk1_small.jpg</IMAGE>
<X>75.78</X>
<Y>813.4</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK3</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>L’aménagement Robert-Bourassa</NAME>
<TITRE>Mise en service entre 1979 et 1981, la centrale Robert-Bourassa est la plus puissante centrale souterraine du monde. C’est la première centrale mise en service dans le cadre du «projet du siècle», soit le complexe La Grande.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk3_small.jpg</IMAGE>
<X>132.4</X>
<Y>805</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK19</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale Jean-Lesage</NAME>
<TITRE>Mise en service entre 1965 et 1967, la centrale Jean-Lesage est le premier aménagement hydroélectrique du complexe Manic-Outardes a être construit. Ce barrage est au second rang des barrages-poids à joints évidés au monde.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk19_small.jpg</IMAGE>
<X>617.9</X>
<Y>1020</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK14</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale Manic-5 et le barrage Daniel-Johnson</NAME>
<TITRE>Mise en service en 1970, la centrale Manic-5 possède le plus grand barrage à voûtes et à contreforts du monde.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk14_small.jpg</IMAGE>
<X>568</X>
<Y>935</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK33</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale de la Première-Chute</NAME>
<TITRE>Mise en service en 1968, la centrale de la Première-Chute témoigne de l’essor d’Hydro-Québec dans les années 1960.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk33_small.jpg</IMAGE>
<X>121.3</X>
<Y>1283.8</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK37</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>La centrale de Carillon</NAME>
<TITRE>Mise en service en 1962, la centrale de Carillon représente le tout premier chantier qu’Hydro-Québec a confié à des ingénieurs québécois.
||Source : Archives d’Hydro-Québec.
</TITRE>
<IMAGE>kk37_small.jpg</IMAGE>
<X>395</X>
<Y>1375.1</Y>
<CLICKABLE>true</CLICKABLE>
</centrale>

<centrale>
<ID>KK38</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En construction</SOUS-TYPE>
<NAME>Centrale de la Romaine-4</NAME>
<X>748</X>
<Y>828</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

<centrale>
<ID>KK39</ID>
<TYPE>Hydroélectrique</TYPE>
<SOUS-TYPE>En service</SOUS-TYPE>
<NAME>Centrale Manic-5-PA </NAME>
<X>570</X>
<Y>950</Y>
<CLICKABLE>non</CLICKABLE>
</centrale>

</dataroot> `
export default xml;
