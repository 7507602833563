import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Button from "../components/button";

export default inject("data")(observer(class Transport extends Component {

	constructor(props) {
		super(props);

		this.state = {
			bigImage: null,
			firstView : true,
			showPopup: true,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.resetTime !== this.props.resetTime) {
			this.setState({
				firstView : true,
				showPopup : true
			});
		}
	}

	closeShownPoi() {
		this.setState({
			showPopup : !this.state.showPopup
		})
	}

	showBigImage(name) {
        const img = new Image();
        img.onload = () => {
            this.setState({
                bigImage : name
            })
        }
        img.onerror = () => {
            if (name.indexOf(".jpg")) {
                name = name.replace(".jpg", ".png");
                this.showBigImage(name)
            }
        }

		img.src = name;
    }

    hideBigImage() {
        this.setState({
            bigImage : null
        })
    }

	toggleView() {
		this.setState({
			firstView : !this.state.firstView
		}, () => {
			this.props.data.forceHideHelpButton = !this.state.firstView

			if (!this.state.firstView) {
				this.props.data.forcedMainTitle = "Le transport de l'électricité au Québec";
			}
			else {
				this.props.data.forcedMainTitle = null;
			}
		})
	}

	render() {
		return (
			<section>
				<div>
					{
					this.state.firstView ?
						<div>
							<div className={"transport-" + this.props.data.menuSectionNo + " transport-" + this.props.data.menuSectionNo + "-" + this.props.data.menuSubSectionNo}></div>
							{
								this.props.data.menuSectionNo === 1 && this.state.showPopup ?
									<div className="shown-poi" style={{top : "140px"}}>
										<div className="shown-pois-content shown-pois-content-bg-2">
											<div className="shown-poi-title unselectable">
												Postes
											</div>
											<div className="shown-poi-text unselectable" style={{fontSize : "20px", lineHeight : "1em", width: "45%"}}>
												Les postes ont de nombreuses fonctions qui visent à mieux contrôler les mouvements de l'énergie lors
												de son transport. Ils permettent de sectionner les longues lignes en tronçons, de les protéger lors des pannes,
												d'effectuer des manoeuvres pour diriger l'énergie sur différentes lignes ou de régler la tension sur le réseau.
											</div>
											<div className="shown-poi-close-btn unselectable">
												<Button onClick={() => this.closeShownPoi()}>
													<img src={"/images/transparent.png"} alt="png" />
												</Button>
											</div>
										</div>
										<div className="shown-poi-image transparent-button" style={{width:"390px", height:"300px", right:"55px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/postes_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="shown-poi-image shown-poi-image-bg-2">
											<img src={"/images/pois/postes_small.jpg"} alt="png" />
										</div>
									</div>
								:
								this.props.data.menuSectionNo === 0 && this.props.data.menuSubSectionNo === 0 && this.state.showPopup ?
									<div className="shown-poi" style={{top : "120px", position:"fixed",zIndex:7}}>
										<img src={"/images/pylons.png"} alt="png" />

										<div className="transparent-button" style={{position:"absolute", left:"45px", top:"50px", width:"450px", height:"300px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/pylone_nappe_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="transparent-button" style={{position:"absolute", left:"570px", top:"330px", width:"450px", height:"270px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/pylone_classique_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="transparent-button" style={{position:"absolute", left:"45px", top:"595px", width:"450px", height:"300px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/pylone_en_v_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="transparent-button" style={{position:"absolute", left:"570px", top:"875px", width:"450px", height:"300px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/pylone_tubulaire_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="transparent-button" style={{position:"absolute", left:"45px", top:"1150px", width:"450px", height:"300px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/pylone_a_chainettes_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div style={{position:"absolute", top: 0, left:"980px"}}>
											<Button onClick={() => this.closeShownPoi()}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
									</div>
								:
								this.props.data.menuSectionNo === 0 && this.props.data.menuSubSectionNo === 1 && this.state.showPopup ?
									<div className="shown-poi" style={{top : "140px"}}>
										<div className="shown-pois-content shown-pois-content-bg-2">
											<div className="shown-poi-title unselectable">
												Ligne à courant continu
											</div>
											<div className="shown-poi-text unselectable" style={{fontSize : "20px", lineHeight : "1em", width: "45%"}}>
												Hydro-Québec dispose d'une ligne à courant continu qui relie la Baie-James à Sandy Pond, près
												de Boston. Cette ligne permet d'isoler les réseaux (à courant alternatif) du Québec et de la
												Nouvelle-Angleterre et de régler la quantité d'électricité transportée.
											</div>
											<div className="shown-poi-close-btn">
												<Button onClick={() => this.closeShownPoi()}>
													<img src={"/images/transparent.png"} alt="png" />
												</Button>
											</div>
										</div>
										<div className="shown-poi-image transparent-button" style={{width:"390px", height:"300px", right:"55px", zIndex:9}}>
											<Button onClick={() => this.showBigImage("/images/pois/lignes_a_courant_continu_big.png")}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
										<div className="shown-poi-image shown-poi-image-bg-2">
											<img src={"/images/pois/lignes_a_courant_continu_small.jpg"} alt="png" />
										</div>
									</div>
								:
								this.props.data.menuSectionNo === 0 && this.props.data.menuSubSectionNo === 2 && this.state.showPopup ?
									<div className="shown-poi" style={{top : "120px", position:"fixed",zIndex:2}}>
										<img src={"/images/equipment.png"} alt="png" />

										<div style={{position:"absolute", top: 0, left:"850px"}}>
											<Button onClick={() => this.closeShownPoi()}>
												<img src={"/images/transparent.png"} alt="png" />
											</Button>
										</div>
									</div>
								:
									null
							}
							{
								this.props.data.menuSectionNo === 2 ?
									<div className="current-animation"></div>
								:
									null
							}
						</div>
					:
						<div className="transport-view-2">
							<div className="montreal-container">
							</div>
							<Button onClick={() => this.toggleView()}>
								<img src={"/images/transparent.png"} alt="png" />
							</Button>
						</div>

					}

					{
						this.state.bigImage !== null ?
							<div className="big-image">
								<Button onClick={() => this.hideBigImage()}>
									<img src={this.state.bigImage} alt="png" />
								</Button>
							</div>
						:
							null
					}

					<div className="zoom-container">
						{
							this.state.firstView ?
								<Button onClick={() => this.toggleView() }>
									<img src="/images/icons/btn-zoom-in.png" alt="png" />
								</Button>
							:
								<Button onClick={() => this.toggleView() }>
									<img src="/images/icons/btn-zoom-out.png" alt="png" />
								</Button>
						}
					</div>
				</div>
			</section>
		);
  	}
}));