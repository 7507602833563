import React, { Component } from 'react';

import Home from "./pages/home.js";
import History from './pages/history.js';
import Production from './pages/production.js';
import Transport from './pages/transport.js';
import Autochtone from './pages/autochtone';
import { observer, Provider } from "mobx-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PageHOC from './components/page-hoc';
import Help from "./components/help";
import { HIDE_CURSOR } from './constants';

import './scss/global.scss';

export default observer(class App extends Component {
	constructor(props) {
		super(props);

		this.stores = {
			data: this.props.data
		};
	}

	render() {
		let section = window.location.pathname.replace("/", "");

		if (section === "") {
			section = "home";
		}

		return (
			<Provider {...this.stores}>
				<Router>
					<div className={"App " + (HIDE_CURSOR ? "" : "with-cursor") } style={{ cursor : HIDE_CURSOR ? "none" : "auto"}}>
						<main role="main" className="container" id={section} >
							<PropsRoute exact path="/" component={PageHOC(Home)} pageName="Accueil" />
							<PropsRoute exact path="/production" component={PageHOC(Production)} pageName="Production" />
							<PropsRoute exact path="/transport" component={PageHOC(Transport)} pageName="Transport" />
							<PropsRoute exact path="/history" component={PageHOC(History)} pageName="Histoire" />
							<PropsRoute exact path="/autochtone" component={PageHOC(Autochtone)} pageName="Autochtone" />

							{
								this.props.data.text !== null ?
									<Help text={this.props.data.text} section={section} menuSectionNo={this.props.data.menuSectionNo} menuSubSectionNo={this.props.data.menuSubSectionNo} />
								:
									null
							}
						</main>
					</div>
				</Router>
			</Provider>
		);
	}
})
const renderMergedProps = (component, ...rest) => {
	const finalProps = Object.assign({}, ...rest);
	return (
	  React.createElement(component, finalProps)
	);
}

const PropsRoute = ({ component, ...rest }) => {
	return (
		<Route {...rest} render={routeProps => {
		return renderMergedProps(component, routeProps, rest);
	}}/>);
}