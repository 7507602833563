import React, { Component} from 'react';
import { observer, inject } from "mobx-react";
import Button from "./button";

export default inject("data")(observer(class SectionMenu extends Component {

    render() {
        return  <div className="sub-section-container">
                    <div className="sub-section-menu-1">
                        {
                            this.props.data.text.sections.map((section, no) => {
                                return <div key={section.title} className={"sub-section-menu-item " + (this.props.data.menuSectionNo === no ? "selected" : "") }>
                                        <Button onClick={() => this.props.onClick(no, -1)}>
                                            {section.title}
                                        </Button>
                                    </div>
                            })
                        }
                    </div>
                    {
                        this.props.data.menuSectionNo > -1 ?
                            <div className={"sub-section-menu-2 sub-section-menu-for-" + this.props.data.menuSectionNo }>
                                {
                                    this.props.data.text.sections[this.props.data.menuSectionNo].subSections.map((subSection, noSub) => {
                                        let title = subSection.title;

                                        return <div key={subSection.title} className={"sub-section-menu-item " + (this.props.data.menuSubSectionNo === noSub ? "selected" : "") }>
                                                    <Button onClick={() => this.props.onClick(this.props.data.menuSectionNo, noSub)}>
                                                        <span dangerouslySetInnerHTML={{__html : title}}></span>
                                                    </Button>
                                                </div>
                                    })
                                }
                            </div>
                        :
                            null
                    }
                </div>
    }
}));